import React from 'react';
import Els from "../assets/svg/els.png";

const BreedCard = ({ breedImg, breedName, breedCount,petType }) => {
  const handleRedirect = () => {
    window.location.href = `/breed/${breedName}/${petType}`;
  };

  return (
    <div className="breed-parent-dv" onClick={handleRedirect}>
      <div className="breed-dv">
        <div className="breed-img">
          <img src={breedImg} alt={breedName} />
          <img src={Els} alt={breedName} id="els" />
        </div>
        <div className="breed-name">
          <h2>{breedName}</h2>
          <p>{breedCount} Available</p>
        </div>
      </div>
    </div>
  );
};

export default BreedCard;
