import React from "react";
import "../assets/css/App.css";
import image1 from "../assets/media/banner.webp";
import Home from "../assets/media/home.webp";

const BannerImage = () => {
  return (
    <>
    <div className="container find-family-member-container">
      
      <img src={image1} alt="goodfurs-banner" />
    
     
  
    </div>
    <div className="container find-family-member-container home">
      
      <img src={Home} alt="goodfurs-banner" />
    
     
  
    </div>
    </>
  );
};

export default BannerImage;
