import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Header from "./pages/commonScreens/header";
import Footer from "./pages/commonScreens/footer";
import "./assets/css/App.css";
import "./assets/css/mobile.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { APIProvider } from "./pages/Context/Context";
import FloatingButton from "./component/FBA";
import Router from "./routers/router";

function App() {
  const location = useLocation();

  useEffect(() => {
    if (location.pathname.slice(1).charAt(0).toUpperCase()) {
      document.title =
        location.pathname.slice(1).charAt(0).toUpperCase() +
        location.pathname.slice(2) +
        " | GoodFurs";
    } else {
      document.title = "GoodFurs ";
    }
  }, [location]);

  return (
    <>
      <Header />
      <APIProvider>
        <Router />
      </APIProvider>
      <Footer />
      <FloatingButton />
    </>
  );
}

export default App;
