import { useState, useEffect } from "react";
import { useAPI } from "../pages/Context/Context";
import { useNavigate } from "react-router-dom";
export const useCategories = () => {
  const { getData } = useAPI();
  const [categories, setCategories] = useState([]);
 
  
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await getData("categories");
        setCategories(response.data);
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };
    fetchCategories();
  }, [getData]);
  return categories;
};

export const useServices = () => {
  const { getData } = useAPI();
  const [services, setServices] = useState([]);
  
useEffect(() => {
  const fetchCategories = async () => {
    try {
      const response = await getData("famous");
      setServices(response.data);
      console.log(response+'response')
    } catch (error) {
      console.error("Error fetching services:", error);
    }
  };
  fetchCategories();
}, [getData]);
return services;
};


export const handleService = (val, navigate) => {
  if (val === "/pet-adoption") {
    navigate(val);
    window.scrollTo(0, 0);
  } else {
    navigate("category" + val);
    window.scrollTo(0, 0);
  }
};
