import React from "react";
import { Container, Card, Row, Col } from 'react-bootstrap';
import Logo from '../../assets/media/logo.png';
import { Link } from "react-router-dom";
import { FaInstagram } from "react-icons/fa6";
import { FaFacebookF } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa6";
import ScrollToTopLink from "./Scroll";
import { FaLinkedinIn } from "react-icons/fa";

const Footer = () => {
  return (
    <>
    <div className="footer-bg">
      
      <div className="container footer">
        <Row>
          <Col md={4} className="footer-logo">
            <img src={Logo} alt="Logo" />
            <div className="socials">
              <ScrollToTopLink to="https://www.instagram.com/easyhaibro.petcare/"><FaInstagram /></ScrollToTopLink>
              <ScrollToTopLink to="/about-us"><FaFacebookF /></ScrollToTopLink>
              <ScrollToTopLink to="/privacy-policy"><FaTwitter /></ScrollToTopLink>
              <ScrollToTopLink to="https://www.linkedin.com/company/easyhaibro/"><FaLinkedinIn /></ScrollToTopLink>
            </div>
            
          </Col>
          <Col md={2}>
            <Card style={{ width: '18rem' }}>
              <Card.Header>Company</Card.Header>
              <ScrollToTopLink to="/about-us">About us</ScrollToTopLink>
              <ScrollToTopLink to="/contact-us">Contact us</ScrollToTopLink>
              <ScrollToTopLink to="/privacy-policy">Privacy Policy</ScrollToTopLink>
              <ScrollToTopLink to="/terms-condition">Terms & Condition</ScrollToTopLink>
              <ScrollToTopLink to="/refund-policy">Refund Policy</ScrollToTopLink>
              
            </Card>
          </Col>

        
          <Col md={2}>
            <Card style={{ width: '18rem' }}>
              <Card.Header>Resources</Card.Header>
              <ScrollToTopLink to="https://api.whatsapp.com/send/?phone=%2B919004044078&text&type=phone_number&app_absent=0" target="_blank">Help Center</ScrollToTopLink>
              <ScrollToTopLink to="" target="_blank">Join Pet Community</ScrollToTopLink>
              <ScrollToTopLink to="https://api.whatsapp.com/send/?phone=%2B919004044078&text&type=phone_number&app_absent=0" target="_blank">Feedback</ScrollToTopLink>
            </Card>
          </Col>
        </Row>
       
      </div>
      </div>
      <div className="footer_end">
        <div className="container end">
          <p>© 2023-2024 Copyright</p>
          <p>RAA EasyHaiBro SERVICES PRIVATE LIMITED</p>
          <p>All Rights Reserved</p>
          <p>Business Developed By <a href="https://rapydlaunch.com" target="_blank">RapydLaunch</a></p>
        </div>

       
      </div>
    </>
    
  );
};

export default Footer;
