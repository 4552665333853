import React, { useState, useEffect } from "react";
import Container from 'react-bootstrap/Container';

const Thanks = () => {
  
  return (
    <>

  <Container fluid className="container category">
     <div className="hyperlinks">
      <a href="/" >Home</a> {` `}/
      <a href="" id="activeLink">Thank You</a>
      <br/>
      <p>We received your enquiry, we will contact you very soon.</p>
      <br/>
      <a href="/">Go Back</a>
     </div>

    
   
   


  
    </Container>

     
    </>
  );
}

export default Thanks;
