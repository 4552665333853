import React, { useState, useEffect } from "react";
import { Container } from "react-bootstrap";
import PetAdoptionComp from "../../../component/Service";
import axios from 'axios';
import { ShimmerPostItem } from "react-shimmer-effects";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import FilterComponent from "../../../component/FilterComponent";
import { useParams } from "react-router-dom";
const BreedSingle = () => {
  const [petData, setPetData] = useState([]);
  const [loading,setLoading]  = useState(false);
  const [filteredData, setFilteredData] = useState([]); 
  const {breedName,petType} = useParams();
  useEffect(() => {
    getData()
  }, []);

  const getData=(queryParams)=>{
    setLoading(true)
    let config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_HOST}adoption?location=&breed=${breedName}&gender=&age=&startDate=&endDate=&petType=dog`,
      headers: { }
    };

    axios.request(config)
    .then((response) => {
    
     if(response.data.status === true){
      setPetData(response.data.data);
      setLoading(false);
      setFilteredData()
     }
     if(response.data.status === false){
      setFilteredData(response.data.message);
      setLoading(false)
     }
     
    })
    .catch((error) => {
      console.log(error);
    });

  }



  const placeholderItems = Array.from({ length: 4 });
  const handleFilter = (filters) => {
    const queryParams = new URLSearchParams(filters).toString();
    getData(queryParams);
    console.log( filters);
  };





  return (
    <Container>

    <div className="top"/>
    <div className="top"/>


    <Container fluid className="container lg-ds">
      <Row>
        <Col lg={12} md={12} sm={12}>
           <div className="services service-head">
             <h2>{petType} Breed</h2>
             <p>{breedName}</p>
             <h4 id="hide">{breedName}</h4>
           </div>
         
        </Col>

        
      
      </Row>
    </Container>


    
    {(!loading && filteredData) &&
 <>
  <div className="GoodFurs-row">
    <div className="GoodFurs5">
      <img src="https://GoodFurs.easyhaibro.com/auth/v1/media/?file=1714835715741.png" />
    </div>
    <div className="GoodFurs5 GoodFurs-404">
      <h2>404 Pet Not Found 🥺</h2>
      <p>Dog/Cat are not our whole life, but they make our lives whole</p>
    </div>
  </div>
  </>
}
      <div className="adoption">

        
      {loading &&
      <div className="category-shimmer">

{placeholderItems.map((_, index) => (
        <ShimmerPostItem
          key={index} // Make sure to provide a unique key
          card
          title
          cta
          imageType="thumbnail"
          imageWidth={50}
          imageHeight={50}
          contentCenter
        />
      ))}
  
      </div>
     }
     
     {(!loading && !filteredData) &&
     <>
      {petData.map((pet, index) => (
          <PetAdoptionComp
            key={index}
            petImage={pet.serviceImage}
            priceRange={`${pet.servicePrice}`}
            petName={pet.serviceName}
            address={pet.petBreed}
            gender={pet.petGender}
            dateOfBirth={pet.dateOfBirth}
            btnName="Whatsapp"
            serviceCity={pet.serviceCity}
            serviceId={pet._id}
            petBreed={pet.petBreed}
          />
        ))}
     </>
}


      </div>
      {/* <Forum /> */}
    </Container>
  );
}

export default BreedSingle;
