// src/router.js
import React from "react";
import { Routes, Route } from "react-router-dom";
import Login from "../pages/authScreens/Login";
import NotFound from "../pages/pages/404";
import Privacy from "../pages/pages/privacy";
import About from "../pages/pages/about";
import Terms from "../pages/pages/terms";
import SearchPage from "../pages/pages/SearchPage";
import PetAdoption from "../pages/pages/Adoption/PetAdoption";
import CatType from "../pages/pages/Adoption/CatType";
import OtherType from "../pages/pages/Adoption/OtherType";
import Thanks from "../pages/pages/Thanks";
import PetDetails from "../pages/pages/Adoption/PetDetails";
import BreedSingle from "../pages/pages/Adoption/BreedCategoiesSingle";
import RefundPolicy from "../pages/pages/RefundPolicy";
import Contact from "../pages/pages/ContactUs";
import DeliveryProcess from "../pages/pages/Delivery";
import DogType from "../pages/pages/Adoption/DogType";

function Router() {
  return (
    <Routes>
       <Route path="/" element={<PetAdoption />} />
      <Route path="/login" element={<Login />} />
      <Route path="/search/:keyword" element={<SearchPage />} /> 
      <Route path="/privacy-policy/" element={<Privacy />} />
      <Route path="/about-us/" element={<About />} />
      <Route path="/terms-condition/" element={<Terms />} />
      <Route path="/pet-type/cat" element={<CatType />} />
      <Route path="/pet-type/dog" element={<DogType />} />
      <Route path="/pet-type/other" element={<OtherType />} />
      <Route path="*" element={<NotFound />} />
      <Route path="/thank-you" element={<Thanks />} />
      <Route path="/pet-adoption/:id" element={<PetDetails />} />
      <Route path="/breed/:breedName/:petType" element={<BreedSingle />} />
      <Route path="/refund-policy" element={<RefundPolicy />} />
      <Route path="/contact-us" element={<Contact />} />
      <Route path="/delivery-process" element={<DeliveryProcess />} />
    </Routes>
  );
}

export default Router;
