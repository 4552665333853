import React from 'react';
import Container from 'react-bootstrap/Container';
import Accordion from 'react-bootstrap/Accordion';
import {useCategories, handleService} from '../services/services';
import { useNavigate } from "react-router-dom";
const PetAdoptionFaq = ({ category, handleService }) => {
  const categories = useCategories();
  const navigate = useNavigate();

  const handleClick = (val) => {
    handleService(val, navigate);
  };

  return (
    <div className="faq">
      <div className="FAQHeader">
        <p>SOME OF THE</p>
        <h2 id="faqsHead">Frequently Asked Questions.</h2>
      </div>
      <Container>
        <div className="faqs">
          <Accordion defaultActiveKey="0" flush>
            <Accordion.Item eventKey="0">
              <Accordion.Header><span>01</span>What types of pets do you offer?</Accordion.Header>
              <Accordion.Body>
              We offer a wide range of pets, including puppies, kittens, small pets like rabbits, guinea pigs, hamsters, and more. We also provide detailed information on each pet’s breed, temperament, and care requirements to help you find the perfect companion.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header><span>02</span> Are the pets vaccinated and health-checked?</Accordion.Header>
              <Accordion.Body>
              Yes, all our pets are thoroughly health-checked and up-to-date on their vaccinations. We work with certified veterinarians to ensure each pet is healthy and ready for their new home. 
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
              <Accordion.Header><span>03</span>What payment options do you accept?</Accordion.Header>
              <Accordion.Body>
              We accept various payment methods, including major credit/debit cards, bank transfers, and UPI. You can select your preferred payment option at checkout. 
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="3">
              <Accordion.Header><span>04</span>How is the delivery of pets handled?</Accordion.Header>
              <Accordion.Body>
              We work with experienced pet transport services to safely deliver your new pet to your doorstep. The delivery process includes temperature-controlled transport and frequent health checks. You will receive tracking information and updates throughout the journey.
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="4">
              <Accordion.Header><span>05</span>Can I visit and see the pets in person before buying?</Accordion.Header>
              <Accordion.Body>
              While we primarily operate online, we understand the importance of meeting your potential pet. We offer virtual meet-and-greets.
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="5">
              <Accordion.Header><span>06</span>What should I prepare for before bringing my new pet home?</Accordion.Header>
              <Accordion.Body>
              Before bringing your new pet home, ensure you have all the necessary supplies, including food, bedding, toys, and grooming tools. We provide a detailed care guide and a starter kit with every adoption to help you get started.

              </Accordion.Body>
            </Accordion.Item>


            <Accordion.Item eventKey="6">
              <Accordion.Header><span>07</span>What if I have questions after bringing my pet home?
              </Accordion.Header>
              <Accordion.Body>
              Our support doesn’t end with the adoption. We offer ongoing customer support to answer any questions you may have about caring for your new pet. You can reach out to us via phone, email, or live chat for assistance.

              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="7">
              <Accordion.Header><span>08</span>What is your return or exchange policy?

              </Accordion.Header>
              <Accordion.Body>
              We understand that sometimes situations change. If you need to return or exchange your pet, please contact us within the specified period outlined in our Return Policy. We will work with you to find a suitable solution, including possible rehoming options.


              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      </Container>
    </div>
  );
};

export default PetAdoptionFaq;

