import React from "react";
import Container from "react-bootstrap/Container";
import { AiFillApple } from "react-icons/ai";
import { IoLogoGooglePlaystore } from "react-icons/io5";
function RefundPolicy() {
  return (
    <Container>
      <div className="mgTagline">
        <div className="tagLeftPrivacy mobile">
          <h2>Refund Policy <span id="update">Updated on : 11/Aug/2024</span></h2>
          <p>
          RAA GoodFurs SERVICES PRIVATE LIMITED ("we," "us," or "our") is committed to protecting the privacy of individuals who use our pet care services. This Privacy Policy outlines the information we collect, how we use it, and the choices you have regarding your personal information.
          </p>
         
    
          <h4>Eligibility for Refunds</h4>
        
          <p>
           <ol>
        <li>If you cancel a booked service at least 24 hours before the scheduled service time, you may be eligible for a full refund. Cancellations made within 24 hours of the service may not be eligible for a refund, but exceptions may be made on a case-by-case basis.</li>
        <li>If you are not satisfied with the service provided, please contact us within 24 hours of the service completion. We will review your concerns and, if they are found to be valid, we may offer a partial or full refund, or a complimentary service.</li>
        <li>If our service provider fails to show up at the scheduled time without prior notice, you are entitled to a full refund or rescheduling of the service at no additional cost.</li>

           </ol>
          </p>


          <h4>Non-Refundable Situations</h4>
          <p>
            <ol>
              <li>If you are not present at the scheduled service time and fail to provide at least 24 hours' notice, the service will be considered rendered, and no refund will be issued.</li>
              <li> If the service has been partially completed and you decide to cancel, a partial refund may be considered based on the proportion of the service that has been completed.</li>
            </ol>
          </p>


          <h4>Refund Process</h4>
         <ol>
           <li> To request a refund, please contact our customer support team at +91 7072110078. Include your booking details and a brief explanation of your request.
</li>
           <li>Refunds will be processed within 2-3 business days of approval. The refund will be credited back to the original method of payment used at the time of booking.
</li>
          

         </ol>

         <h4>Changes to the Refund Policy</h4>
         <ol>
           <li>We reserve the right to modify or update this Refund Policy at any time. Any changes will be posted on our website, and it is your responsibility to review the policy periodically.

</li>
         
         </ol>

        

        </div>
      </div>
    </Container>
  );
}

export default RefundPolicy;
