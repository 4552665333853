import React from "react";
import Container from "react-bootstrap/Container";
import { AiFillApple } from "react-icons/ai";
import { IoLogoGooglePlaystore } from "react-icons/io5";
function Contact() {
  return (
    <Container>
      <div className="mgTagline">
        <div className="tagLeftPrivacy mobile">
          <h2>Contact us</h2>
          <h4>Get in Touch          </h4>
          <p>
          If you have any questions, need assistance, or want to know more about our available pets, feel free to reach out to us:

         </p>
         <p>
         <bold>Phone/WhatsApp</bold>: +91 87796-92292
         </p>
         <p></p>
         
    
          <h4>Our Mission:</h4>
        
          <p>
          Our team is available from <bold>9 AM to 8 PM</bold>, Monday to Saturday. Reach out to us directly or drop a message, and we’ll get back to you promptly.

          </p>
          <p>
          <bold>Email</bold>: petcare.easyhaibro@gmail.com

          </p>

          <p>
          We’re happy to answer your inquiries via email. Expect a response within 24 hours on weekdays.

          </p>


          <p>
          <bold>Address</bold>: F-1-A-1-1 SECTOR 3E/B MUMBRA PANVEL HIGHWAY, OPPOSITE FIRE BRIGADE, KALAMBOLI, NAVI MUMBAI, MAHARASHTRA 410218


          </p>
          
        </div>
      </div>
    </Container>
  );
}

export default Contact;
