import React from "react";
import Container from "react-bootstrap/Container";
import { AiFillApple } from "react-icons/ai";
import { IoLogoGooglePlaystore } from "react-icons/io5";
function Privacy() {
  return (
    <Container>
      <div className="mgTagline">
        <div className="tagLeftPrivacy mobile">
          <h2>Privacy Policy</h2>
          <p>
          RAA GoodFurs SERVICES PRIVATE LIMITED ("we," "us," or "our") is committed to protecting the privacy of individuals who use our pet care services. This Privacy Policy outlines the information we collect, how we use it, and the choices you have regarding your personal information.
          </p>
         
    
          <h4>Information We Collect</h4>
        
          <p>
           <ol>
        <li>Personal Information:** When you use our services, we may collect personal information such as your name, address, email address, and phone number.</li>
        <li>Pet Information:** We may collect information about your pets, including their names, breeds, and medical history.</li>
        <li>Payment Information:** If you make payments through our platform, we may collect payment information, including credit card details.</li>

           </ol>
          </p>


          <h4>How We Use Your Information</h4>
          <p>
            <ol>
              <li>Service Delivery:** We use your information to provide and improve our pet care services, including scheduling appointments, delivering pet care products, and coordinating with service providers.</li>
              <li>Communications:** We may use your contact information to communicate with you about appointments, updates, and promotional offers.</li>
             <li>Payment Processing:** Your payment information is used to process payments for the services you receive.</li>
            </ol>
          </p>


          <h4>Sharing of Information</h4>
         <ol>
           <li>Service Providers:** We may share your information with third-party service providers who assist in delivering our services.
</li>
           <li>Legal Compliance:** We may disclose your information when required by law or to protect our rights.
</li>
          

         </ol>

         <h4>Data Security</h4>
         <ol>
           <li>Opt-Out:** You may opt-out of receiving promotional communications from us by following the instructions provided in such communications.</li>
           <li>Access and Correction:** You have the right to access and correct your personal information. You can update your information through your account settings or by contacting us.</li>
         </ol>

         <h4>Cookies</h4>
         <ol>
           <li>We use cookies and similar technologies to enhance your experience on our website. You can manage your cookie preferences through your browser settings.</li>
         </ol>

<h4>Changes to the Privacy Policy</h4>
         <ol>
           <li>We reserve the right to update this Privacy Policy to reflect changes in our practices. We will notify you of any material changes by posting the updated policy on our website.</li>
         
         </ol>
         
         
         <h4>Contact Information</h4>
         <ol>
           <li>f you have any questions or concerns about our Privacy Policy, please contact us at info@easyhaibro.com</li>
         
         </ol>

<p>By using our services, you agree to the terms of this Privacy Policy. If you do not agree with our practices, please do not use our services.
</p>
        </div>
      </div>
    </Container>
  );
}

export default Privacy;
